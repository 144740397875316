<template>
  <v-card
    :color="formattedItem.colorItem"
    flat
    :ripple="false"
    tile
  >
    <v-list-item
      two-line
    >
      <v-list-item-content>
        <v-list-item-title
          class="break-word"
        >
          <span v-html="formattedItem.title" />
        </v-list-item-title>
        <v-list-item-subtitle
          v-snip:js="5"
          class="break-word"
        >
          <span v-html="formattedItem.subtitle" />
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-actions>
      <div>
        <v-chip
          v-if="formattedItem.ffijada"
          label
          dark
          color="red"
          small
          class="mr-1 mt-1"
        >
          <span
            v-if="formattedItem.horaFijada === '00:00'"
          >
            Fijado
          </span>
          <span
            v-else
          >
            Fijado {{ formattedItem.horaFijada }} hs
          </span>
        </v-chip>
        <v-chip
          v-if="formattedItem.clasificacion !== PARTE_TRABAJO.clasificaciones.normal"
          color="info"
          label
          small
          class="mr-1 mt-1"
        >
          {{ PARTE_TRABAJO.descripcionesClasificacion[formattedItem.clasificacion] }}
        </v-chip>
        <v-chip
          :style="{ 'background-color': formattedItem.prioridad_color_fondo, 'color': formattedItem.prioridad_color_font }"
          label
          small
          class="mr-1 mt-1"
        >
          {{ formattedItem.prioridad_alias ? formattedItem.prioridad_alias : formattedItem.prioridad_descripcion }}
        </v-chip>
        <v-chip
          label
          :color="formattedItem.colorTactuacion"
          :text-color="formattedItem.colorTextoTactuacion"
          small
          class="mr-1 mt-1"
        >
          {{ formattedItem.tactuacion_tipo_desc }}
        </v-chip>
        <template
          v-if="formattedItem.estado === PARTE_TRABAJO.estados.pendiente"
        >
          <v-chip
            v-if="formattedItem.cant_acciones_pendientes"
            label
            :color="PARTE_TRABAJO.colores.conAccionesPendientes"
            small
            class="mr-1 mt-1"
          >
            {{ formattedItem.cant_acciones_realizadas }}/{{ formattedItem.cant_acciones }}
          </v-chip>
          <v-chip
            v-else-if="formattedItem.cant_acciones"
            label
            :color="PARTE_TRABAJO.colores.conAccionesTodasRealizadas"
            text-color="white"
            small
            class="mr-1 mt-1"
          >
            {{ formattedItem.cant_acciones }}
          </v-chip>
        </template>
        <template
          v-else
        >
          <v-chip
            label
            color="info"
            dark
            small
            class="mr-1 mt-1"
          >
            {{ formattedItem.desc_estado_ot }}
          </v-chip>
        </template>
        <v-icon
          v-show="formattedItem.idtmotivo_bloqueo_ot"
          small
          color="red"
          class="mr-1 mt-1"
        >
          {{ $vuetify.icons.values.locked }}
        </v-icon>
        <v-icon
          v-show="formattedItem.avisar_antes_ir"
          small
          color="red"
          class="mr-1 mt-1"
        >
          {{ $vuetify.icons.values.avisarAntesDeIr }}
        </v-icon>
        <v-icon
          v-show="formattedItem.fprevista"
          small
          class="mr-1 mt-1"
        >
          {{ $vuetify.icons.values.calendar }}
        </v-icon>
        <v-icon
          v-show="formattedItem.observacion"
          small
          class="mr-1 mt-1"
        >
          {{ $vuetify.icons.values.chat }}
        </v-icon>
        <v-icon
          v-show="formattedItem.descargado && !formattedItem.descargado_por_mi"
          small
          color="red"
          class="mr-1 mt-1"
        >
          {{ $vuetify.icons.values.user }}
        </v-icon>
      </div>
      <v-spacer />
      <template
        v-if="!formattedItem.noEncontrado && !selecting"
      >
        <slot
          name="actions"
          :item="formattedItem"
        />
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import _ from '@/utils/lodash'
import { BASE_COLORS, CHECKLIST, PARTE_TRABAJO } from '@/utils/consts'
import { fontColor } from '@/utils/ui'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    selecting: {
      type: Boolean,
      default: false,
    },
    inIdsPartesOffline: {
      type: Array,
      default: () => [],
    },
    selectedListItems: {
      type: Array,
      default: () => [],
    },
  },
  data () {
    return {
      PARTE_TRABAJO
    }
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.offline = this.inIdsPartesOffline.indexOf(item.idparte_trabajo) >= 0
      item.title = this.$online.parteTrabajo.title(item)
      item.subtitle = this.$online.parteTrabajo.subtitle(item)
      item.fechaPrevista = `${this.$options.filters.shortDate(item.fprevista)}`
      item.fechaFijada = `${this.$options.filters.shortDate(item.ffijada)}`
      item.horaFijada = `${this.$options.filters.shortTime(item.ffijada)}`
      item.cant_acciones = item.cant_acciones_realizadas + item.cant_acciones_pendientes
      item.noEncontrado = (item.descargado_por_mi && !item.offline)
      item.colorTactuacion = item.checklist_con_anomalias ? CHECKLIST.colores.conAnomalias : null
      item.colorTextoTactuacion = item.checklist_con_anomalias ? fontColor(CHECKLIST.colores.conAnomalias) : null
      if (item.noEncontrado) {
        item.colorItem = PARTE_TRABAJO.colores.noEncontrado
      } else if (item.offline) {
        item.colorItem = PARTE_TRABAJO.colores.descargado
      } else if (this.selectedListItems.length > 0) {
        const idsPartesSeleccionados = _.map(this.selectedListItems, 'idparte_trabajo')
        if (idsPartesSeleccionados.includes(item.idparte_trabajo)) {
          item.colorItem = BASE_COLORS.selected
        }
      } else {
        item.colorItem = null
      }
      item.asignadoAMi = item.asignado_a_mi === 1
      return item
    },
  }
}
</script>

<style scoped>
.break-word {
  word-break: break-word;
  white-space: normal;
}
</style>
