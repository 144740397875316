import { render, staticRenderFns } from "./ParteListItem.vue?vue&type=template&id=14b0babe&scoped=true&"
import script from "./ParteListItem.vue?vue&type=script&lang=js&"
export * from "./ParteListItem.vue?vue&type=script&lang=js&"
import style0 from "./ParteListItem.vue?vue&type=style&index=0&id=14b0babe&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14b0babe",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardActions,VChip,VIcon,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VSpacer})
